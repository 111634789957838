
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { ElNotification } from 'element-plus';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'mid-course-summary-report',
  components: {
    Datatable,
    Multiselect,

  },
  data() {
    return {
      api_url: '',
      start_date: '',
      end_date: '',
      entity_id: [] as any,
      course_info_id: '' as any,
      tranche_id: [] as any,
      association: [] as any,
      optionsTPartner: [] as any,
      Totalassessed: 0 as any,
      Totaltarget: 0 as any,
      Totalassessed_female: 0 as any,
      Totalenrollment: 0 as any,
      Totalenrollment_female: 0 as any,
      Totalcertification: 0 as any,
      Totalcertification_female: 0 as any,
      Totaljobplacement: 0 as any,
      Totaljobplacement_female: 0 as any,
      data: [],
      tranches_info: [] as any,
      optionsTranche: [] as any,
      courses: [] as any,
      courseSummaryReport: [] as any,
      componentKey: 0,
      loading: false,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      showmidLevelCourseReport: false,
      selectObj: {
        id: 'select',
        entity_short_name: 'Select All Training Partner',
        entity_name: 'Select All Training Partner',
      } as any,
      UnselectObj: {
        id: 'unselect',
        entity_short_name: 'Unselect All Training Partner',
        entity_name: 'Unselect All Training Partner',
      } as any,
      tempAssociation: [] as any,
      tempId: [] as any,
      associationData: [] as any,
      selectCourseObj: {
        id: 'select',
        course_name: 'Select All Mid Level Course',
      } as any,
      UnselectCourseObj: {
        id: 'unselect',
        course_name: 'Unselect All Mid Level Course',
      } as any,
      tempCouse: [] as any,
      tempCourseId: [] as any,
      courseData: [] as any,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranches();
    await this.getAssociation();
    await this.getMidLavelCourses();
  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.entity_id);
      formData.set('course_info_id', this.course_info_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);
      await ApiService.post('report/mid-level-course-pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.entity_id);
      formData.set('course_info_id', this.course_info_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/mid-level-course-export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Mid Level Course Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async printPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('tranche_id', this.tranche_id);
      formData.set('entity_id', this.entity_id);
      formData.set('course_info_id', this.course_info_id);
      formData.set('start_date', this.start_date);
      formData.set('end_date', this.end_date);

      let data = `${this.VUE_APP_API_URL}/api/report/mid-level-course-print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    filterCourse(course) {
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.courseData.push(course);
      if (course.includes('select')) {
        this.courseData = [] as any;
        this.courseData.push(this.tempCourseId);
        this.courses.shift();

        this.courses.forEach((val, index) => {
          if (val.id != 'select') {
            this.course_info_id.push(val.id);
          }
        });

        // association khali kore push korabo unselect status
        this.courses = [];
        this.courses.push(this.UnselectCourseObj);
      }
      if (course.includes('unselect')) {
        this.courseData = [];
        //unshift koro select obj
        this.courses = [];

        this.courses = this.tempCouse;
        this.courses.unshift(this.selectCourseObj);
        this.course_info_id = '';
      }
    },

    filterEntity(entity) {
      this.getMidLavelCourses();
      this.associationData = [];
      // select korai dibo sob  first index shift kore
      this.associationData.push(entity);
      if (entity.includes('select')) {
        this.associationData = [] as any;
        this.associationData.push(this.tempId);
        this.association.shift();

        this.association.forEach((val, index) => {
          if (val.id != 'select') {
            this.entity_id.push(val.id);
          }
        });
        // association khali kore push korabo unselect status
        this.association = [];
        this.association.push(this.UnselectObj);
      }
      if (entity.includes('unselect')) {
        this.associationData = [];
        //unshift koro select obj
        this.association = [];

        this.association = this.tempAssociation;
        this.association.unshift(this.selectObj);
        this.entity_id = '';
        // tempassociation theke association vorbo
        //batch.association id empty
      }
    },
    async getTranches() {
      this.load = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {

          this.tranches_info = response.data.data;
          this.tranches_info.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          this.load = true;
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
          this.association.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getMidLavelCourses() {
      this.loading = true;
      let entity_id = this.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        'course/list?entity_id=' +
        entity_id +
        '&tranche=' +
        this.tranche_id + '&isManagementCourse=1'
      )
        .then((response) => {
          this.courses = response.data.data;
          this.tempCouse = this.courses;

          this.courses.forEach((val, index) => {
            this.tempCourseId.push(val.id);
          });
          this.courses.unshift(this.selectCourseObj);
          this.loading = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    midLevelCourseReport() {
      this.loading = true;
      ApiService.get(
        'report/mid-level-course?tranche_id=' +
        this.tranche_id +
        '&entity_id=' +
        this.entity_id +
        '&course_info_id=' +
        this.course_info_id +
        '&start_date=' +
        this.start_date +
        '&end_date=' +
        this.end_date

      )
        .then((response) => {
          this.loading = false;
          this.courseSummaryReport = response.data.data.coursedata;
          this.Totaltarget = response.data.data.Totaltarget;
          this.Totalenrollment = response.data.data.Totalenrollment;
          this.Totalenrollment_female =
            response.data.data.Totalenrollment_female;
          this.Totalassessed = response.data.data.Totalassessed;
          this.Totalassessed_female =
            response.data.data.Totalassessed_female;
          this.Totalcertification = response.data.data.Totalcertification;
          this.Totalcertification_female =
            response.data.data.Totalcertification_female;
          this.Totaljobplacement = response.data.data.Totaljobplacement;
          this.Totaljobplacement_female =
            response.data.data.Totaljobplacement_female;
          this.loading = false;
          this.showCourseNotice = true;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
  },
  setup() { },
});
